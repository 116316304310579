import './components/sentry';
import countdown from "./components/countdown";
import '../style/style.scss';
import initLazyLoad from './components/lazy';
import initLetterNavigation from './components/letter_navigation';
import './components/readmore';
import initSearchBar from './components/search_bar';
import './components/scrollto_link';
import './components/quantity_control';
import './screens/order/repeat';
import initModal from "./components/modal";
import './components/brands_filter';
import initClickToCopy from "./components/click_to_copy";
import initMobileNavigation from "./components/mobile_navigation";
import { initFavorites } from "./components/product/favorites";
import initLocation from "./components/location";
import initAjaxTabs from "./components/ajax_tabs";
import initHeadroom from "./components/headroom";
import initTabs from "./components/tabs";
import initClampButton from "./components/readmore";
import initMenuToggle from "./components/menu_toggle";
import { updateCartOffers } from "./components/cart";
import initCookieBox from "./components/cookie_box";
import initInfoBox from "./components/info_box";
document.addEventListener('DOMContentLoaded', function () {
    countdown(document.querySelector('.bnn__countdown'));
});
initFavorites();
initLazyLoad();
initModal();
initHeadroom();
initLetterNavigation();
initSearchBar();
initClickToCopy('.copy-click');
initMobileNavigation();
initLocation();
initAjaxTabs();
initTabs();
initClampButton();
initMenuToggle();
updateCartOffers();
initCookieBox();
initInfoBox();
